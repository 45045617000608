<template>
  <section class="ant-layout site-layout">
    <header class="ant-layout-header site-header">
      <div class="flex-between i-back-normal-head">
        <div class="i-back-head-left">
          <span class="i-back-normal-head-text">订单</span>
        </div>
        <div class="i-back-normal-head-right-text"></div>
      </div>
    </header>
    <main class="ant-layout-content main-content">
      <div class="order flex-column">
        <div class="i-card" style="height: 100%; margin: 16px 0px">
          <div class="ant-table ant-table-small order-table-header">
            <table style="table-layout: auto;width: 100%; height: 48px;background: rgb(255, 255, 255);">
              <thead class="ant-table-thead">
                <tr class="ant-table-row ant-table-row-level-0">
                  <th class="ant-table-cell">订单号</th>
                  <th class="ant-table-cell">商品名称</th>
                  <th class="ant-table-cell">支付方式</th>
                  <th class="ant-table-cell">订单金额</th>
                  <th class="ant-table-cell">优惠金额</th>
                  <th class="ant-table-cell">付款金额</th>
                  <th class="ant-table-cell">支付时间</th>
                  <th class="ant-table-cell">订单状态</th>
                  <th class="ant-table-cell">下单时间</th>
                  <th class="ant-table-cell">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in orderList" :key="order.id" class="ant-table-row ant-table-row-level-0">
                  <td class="ant-table-cell">{{order.orderNo}}</td>
                  <td class="ant-table-cell" style="max-width: 150px;">{{order.productName}}</td>
                  <td class="ant-table-cell">{{order.payMethod == 1 ? '微信支付' : '支付宝支付'}}</td>
                  <td class="ant-table-cell">{{order.orderPrice}}</td>
                  <td class="ant-table-cell">{{order.deratePrice}}</td>
                  <td class="ant-table-cell">{{order.actualPrice}}</td>
                  <td class="ant-table-cell">{{order.payTime}}</td>
                  <td class="ant-table-cell">{{order.orderStatusText}}</td>
                  <td class="ant-table-cell">{{order.orderTime}}</td>
                  <td class="ant-table-cell">
                    <button v-if="order.orderStatus == 16" @click="applyInvoince(order)" type="button" class="ant-btn i-button">
                      <span>申请发票</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="total == 0" class="ant-empty flex-column-center" style="height: 100%">
            <div class="ant-empty-image" style="height: 174px">
              <img alt="暂无数据" src="../../assets/static/empty_data.7dd60602.svg" />
            </div>
            <div class="ant-empty-description">暂无数据</div>
          </div>
        </div>
      </div>
    </main>

    <CustomModal v-model="showOrderInvoiceModal" title="申请发票">
      <div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input v-model="invoice.type" class="ant-input i-input" maxlength="10" type="radio" name="type" :value="0"/>
                    个人
                    <input v-model="invoice.type" class="ant-input i-input" maxlength="10" type="radio" name="type" :value="1"/>
                    公司
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input
                      v-model="invoice.invoiceHeader"
                      class="ant-input i-input"
                      placeholder="发票抬头"
                      maxlength="10"
                      type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input
                      v-model="invoice.amount"
                      class="ant-input i-input islide-input"
                      placeholder="发票金额"
                      type="number"
                      disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="invoice.type == 1" class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input
                      v-model="invoice.dutyParagraph"
                      class="ant-input i-input islide-input"
                      placeholder="税号"
                      type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input
                      v-model="invoice.phone"
                      class="ant-input i-input islide-input"
                      placeholder="手机号"
                      type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <div class="account-input">
                    <input
                      v-model="invoice.email"
                      class="ant-input i-input islide-input"
                      placeholder="邮箱"
                      type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ant-row ant-form-item" style="margin-bottom: 0px">
            <div class="ant-col ant-form-item-control">
              <div class="ant-form-item-control-input">
                <div class="ant-form-item-control-input-content">
                  <button @click="submitOrderInvoice" class="ant-btn i-button button-red only-button">
                    <span>提交</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </CustomModal>

  </section>
</template>

<script>
import {formatDate} from '@/utils';

export default {
  name: "order",
  data() {
    return {
       total: 0,
       page: 1,
       size: 20,
       pageTotal: 0,
       orderList: [],
       showOrderInvoiceModal: false,
       invoice: {
         orderNo: '',
         type: 0,
         invoiceHeader: '',
         amount: '',
         dutyParagraph: '',
         phone: '',
         email: ''
       }
    }
  },
  created(){
    this.getOrderList();
  },
  methods: {
    getOrderList(){
       this.$http.get('/api/user/getOrderList',{pn: this.page,ps: this.size}).then(data=>{
          data.list.forEach(order => {
             order.payTime = formatDate(order.payTime,'YYYY-MM-DD HH:mm');
             order.orderTime = formatDate(order.orderTime,'YYYY-MM-DD HH:mm');
          });
          this.orderList = data.list;
          this.page = data.pageNum;
          this.size = data.pageSize;
          this.total = data.total;
          this.pageTotal = data.pages;
       });
    },
    applyInvoince(order){
       this.invoice.orderNo = order.orderNo;
       this.invoice.amount = order.actualPrice;
       this.showOrderInvoiceModal = true;
    },
    submitOrderInvoice(){
       this.$http
        .post("/api/user/submitOrderInvoice", this.invoice)
        .then(() => {
          this.$Message.success("提交成功");
          this.getOrderList();
          this.showOrderInvoiceModal = false;
        });
    }
  }
};
</script>
<style scoped>
.order {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
table tbody>tr>td {
  padding: 12px 8px;
  text-align: center;
}
table tbody>tr>td:first-child {
  padding-left: 24px;
  text-align: left;
}
table tbody>tr>td:last-child {
  padding-right: 24px;
  text-align: right;
}
</style>